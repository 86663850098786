import React from "react";

const Discover = () => {
  return (
    <>
      <section style={{ backgroundColor: "#202026" }}>
        <div
          style={{
            maxWidth: "1000px",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: "3em",
            textAlign: "center",
            paddingBottom: "3em",
          }}
        >
          <h1
            style={{ color: "white", fontWeight: "400", padding: "0px 20px" }}
          >
            Discover the optimal <b>imaging solution</b> for{" "}
            <b style={{ color: "#FFA100" }}>your</b> practice
          </h1>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a
              href="https://wa.me/4915234689005?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about"
              target="t_blank"
            >
              <button className="talk">Talk to a specialist</button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Discover;
